import React from 'react'
import { css } from '@emotion/react'
import { colors, mq } from 'plume-ui'
import { APPBAR_HEIGHT } from '../header/AppBar'
import { APPBAR_HEIGHT as APPBAR_HEIGHT_FOR_MOBILE } from '../header/AppBarForMobile'

type Props = {
  id?: string
  show?: boolean
  children: React.ReactNode
}

export const Menu: React.FC<Props> = (props) => {
  const show = typeof props.show === 'boolean' ? props.show : true
  return show ? (
    <div
      id={props.id}
      css={css`
        position: fixed;
        right: 0;
        top: calc(${APPBAR_HEIGHT_FOR_MOBILE});
        background-color: ${colors.white00};
        z-index: 30;
        min-width: 180px;
        max-width: 480px;

        border-bottom: solid 1px ${colors.gray50};
        border-left: solid 1px ${colors.gray50};

        & > a {
          display: block;
          color: ${colors.text};
          &:hover {
            background-color: ${colors.backgroundGray};
          }
          padding: 10px 14px 9px;
        }
        ${mq.large} {
          top: calc(${APPBAR_HEIGHT} - 1px);
        }
      `}
    >
      {props.children}
    </div>
  ) : null
}
