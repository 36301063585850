import { css, SerializedStyles } from '@emotion/react'
import { colors } from 'plume-ui'
import { Transition } from 'react-transition-group'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import { useRouter } from 'next/router'
import { isBrowser } from '@/lib/browser'
import { suggest, escapeKeyword } from '@/lib/api/suggestion'
import { SuggestList, HighlightState } from './SuggestList'
import {
  createPathBaseOnType,
  DomesticCompanySuggestItem,
  DomesticInvestorSuggestItem,
  OverseasCompanySuggestItem,
  OverseasInvestorSuggestItem,
  SuggestData,
  TagSuggestItem,
  TransitTo,
} from './SuggestItem'
import { CloseButton } from './CloseButton'
import { EASING_FAST, EASING_SLOW } from '@/lib/easings'
import { printError } from '@/lib/log'
import { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'

const ITEM_HEIGHT = 32

const ESC_KEY = 27
const UP_KEY = 38
const DOWN_KEY = 40
const MAX_ITEMS = 16
const MAX_TAG_ITEMS = 8
const MAX_DOMESTIC_COMPANIES = 6
const MAX_OVERSEAS_COMPANIES = 6
const MAX_DOMESTIC_INVESTORS = 6
const MAX_OVERSEAS_INVESTORS = 6

type SuggestResult = {
  tags: TagSuggestItem[]
  domesticCompanies: DomesticCompanySuggestItem[]
  overseasCompanies: OverseasCompanySuggestItem[]
  domesticInvestors: DomesticInvestorSuggestItem[]
  overseasInvestors: OverseasInvestorSuggestItem[]
  displayLength: number
}

const initialKeyword = () => {
  if (!isBrowser || window.location.pathname === 'search') {
    return ''
  }
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('q') || ''
}

const globalSearchPath = (keyword: string) =>
  `/search?q=${escapeKeyword(keyword)}&from=suggest`

export const GlobalSearchForm = () => {
  const router = useRouter()
  useEffect(() => {
    router.events.on('routeChangeStart', clearSuggest)
    return () => {
      router.events.off('routeChangeStart', clearSuggest)
    }
  }, [])

  const [keyword, setKeyword] = useState(initialKeyword())
  const [handle, setHandle] = useState<NodeJS.Timeout | null>(null)
  const [req, setReq] = useState(0)
  const [loadingStatus, setLoadingStatus] = useState<
    'loading' | 'done' | 'none'
  >('none')

  useEffect(() => {
    if (handle) {
      clearTimeout(handle)
    }
    const newHandle = setTimeout(() => {
      setReq(new Date().getTime())
    }, 350)
    setHandle(newHandle)
  }, [keyword])

  const clearSuggest = () => {
    setShowSuggest(false)
    setKeyword('')
    setShowSuggest(false)
    setResult({
      tags: [],
      domesticCompanies: [],
      overseasCompanies: [],
      domesticInvestors: [],
      overseasInvestors: [],
      displayLength: 0,
    })
    setLoadingStatus('none')
  }

  const [showSuggest, setShowSuggest] = useState(false)
  const [ctrl, setCtrl] = useState<any>(null)
  const [result, setResult] = useState({
    tags: [],
    domesticCompanies: [],
    overseasCompanies: [],
    domesticInvestors: [],
    overseasInvestors: [],
    displayLength: 0,
  } as SuggestResult)
  const [highlight, setHighlight] = useState<HighlightState>(null)

  useEffect(() => {
    const run = async () => {
      if (ctrl) {
        ctrl.abort()
      }
      const abort = new AbortController()
      setCtrl(abort)
      try {
        const data: any = await suggest(keyword, { signal: abort.signal })
        const suggestCandidateList = [
          ...data
            .filter((item: any) => item.type === 'tag')
            .slice(0, MAX_TAG_ITEMS),
          ...data
            .filter((item: any) => item.type === 'domestic-company')
            .slice(0, MAX_DOMESTIC_COMPANIES),
          ...data
            .filter((item: any) => item.type === 'domestic-investor')
            .slice(0, MAX_DOMESTIC_INVESTORS),
          ...data
            .filter((item: any) => item.type === 'overseas-company')
            .slice(0, MAX_OVERSEAS_COMPANIES),
          ...data
            .filter((item: any) => item.type === 'overseas-investor')
            .slice(0, MAX_OVERSEAS_INVESTORS),
        ].slice(0, MAX_ITEMS)
        setResult({
          tags: suggestCandidateList.filter((item: any) => item.type === 'tag'),
          domesticCompanies: suggestCandidateList.filter(
            (item: any) => item.type === 'domestic-company'
          ),
          overseasCompanies: suggestCandidateList.filter(
            (item: any) => item.type === 'overseas-company'
          ),
          domesticInvestors: suggestCandidateList.filter(
            (item: any) => item.type === 'domestic-investor'
          ),
          overseasInvestors: suggestCandidateList.filter(
            (item: any) => item.type === 'overseas-investor'
          ),
          displayLength: suggestCandidateList.length,
        })
        setLoadingStatus('done')
      } catch (e) {
        printError(e)
      }
    }
    const cancel = () => {
      if (ctrl) {
        ctrl.abort()
        setCtrl(null)
      }
      setShowSuggest(false)
      setResult({
        tags: [],
        domesticCompanies: [],
        overseasCompanies: [],
        domesticInvestors: [],
        overseasInvestors: [],
        displayLength: 0,
      })
      setLoadingStatus('none')
    }
    if (keyword) {
      setLoadingStatus('loading')
      setShowSuggest(true)
      run()
    } else {
      cancel()
    }
  }, [req])

  const refGlobalSearch = useRef<HTMLDivElement>(null)
  useOnClickOutside([refGlobalSearch], () => {
    setShowSuggest(false)
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowSuggest(true)
    setKeyword(e.currentTarget.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === ESC_KEY) {
      setShowSuggest(false)
    }
  }

  const handleFormKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (result.displayLength === 0) {
      return // 結果が返ってきてないときは無視する
    }
    switch (e.keyCode) {
      case UP_KEY:
        if (highlight === null) {
          setHighlight(result.displayLength - 1)
        } else {
          if (highlight > 0) {
            setHighlight(highlight - 1)
          } else {
            setHighlight(null)
          }
        }
        break
      case DOWN_KEY:
        if (highlight === null) {
          setHighlight(0)
        } else {
          if (highlight >= result.displayLength - 1) {
            setHighlight(null)
          } else {
            setHighlight(highlight + 1)
          }
        }
        break
    }
  }

  const refInputField = useRef<HTMLInputElement>(null)
  const handleSubmit = (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLDivElement>
      | null
  ) => {
    e?.preventDefault()
    if (highlight === null) {
      router.push(globalSearchPath(keyword))
    } else {
      const map = composeMapForHighlight(result)
      const item = map[highlight]
      router.push(item.itemPath)
    }

    if (refInputField.current) {
      refInputField.current.blur()
    }
    clearSuggest()
  }

  const handleClose = () => {
    clearSuggest()
    if (refInputField.current) {
      refInputField.current.focus()
    }
  }

  return (
    <div
      css={css`
        position: relative;
        top: calc(50% - 40px / 2);
        display: flex;
        align-items: flex-start;
        flex-grow: 1;
        align-self: flex-start;
        padding: 0 16px 0 0;
      `}
    >
      <div
        ref={refGlobalSearch}
        css={css`
          width: 337px;
          background: ${colors.white00};
          border: 1px solid ${colors.border};
          border-radius: 20px;
          ${showSuggest && 'padding: 0 0 4px;'}
          ${showSuggest && 'border-radius: 8px;'}
          :hover {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
          }
          :focus-within {
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            outline: -webkit-focus-ring-color auto 1px;
            border: 2px solid;
          }
        `}
      >
        <form
          action="/search"
          method="get"
          css={css`
            height: 40px;
            transition:
              background-color 0.16s ${EASING_FAST},
              box-shadow 0.19s linear 0.07s;
            box-sizing: border-box;
          `}
          onSubmit={handleSubmit}
          onKeyDown={handleFormKeyDown}
          className="global-search-form"
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              height: 100%;
            `}
          >
            <IcomoonIcon
              name="search"
              css={css`
                font-size: 20px;
                color: ${colors.textLight};
                display: inline-block;
                margin-left: 16px;
                transition: color 0.16s ${EASING_FAST};
                &:has(+ input[name='keyword']:focus) {
                  color: ${colors.cyan60};
                }
              `}
            />
            <input
              className="search-input"
              name="keyword"
              placeholder="スタートアップ、投資家、タグなどを検索"
              type="text"
              autoComplete="off"
              value={keyword}
              ref={refInputField}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              css={css`
                color: ${colors.text};
                width: calc(100% - 50px);
                height: 100%;
                border: none;
                padding: 0 0 0 8px;
                background-color: transparent;
                outline: none;
                &::placeholder {
                  color: ${colors.textLight};
                  font-size: 14px;
                }
              `}
            />
            <Transition
              in={keyword.length > 0}
              timeout={{ enter: 140, exit: 120 }}
            >
              {(status) => (
                <CloseButton status={status} onClick={handleClose} />
              )}
            </Transition>
          </div>
        </form>
        <Transition in={true} timeout={{ enter: 180, exit: 160 }}>
          <div
            css={css`
              ${transitionCss(result.displayLength, showSuggest)};
            `}
          >
            <SuggestResultList
              loadingStatus={loadingStatus}
              keyword={keyword}
              handleSubmit={handleSubmit}
              result={result}
              highlight={highlight}
            />
          </div>
        </Transition>
      </div>
    </div>
  )
}

type SuggestResultListProps = {
  loadingStatus: 'loading' | 'done' | 'none'
  keyword: string
  handleSubmit: (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLDivElement>
      | null
  ) => void
  result: SuggestResult
  highlight: HighlightState
}

const order = [
  'tags',
  'domesticCompanies',
  'overseasCompanies',
  'domesticInvestors',
  'overseasInvestors',
] as const

type HighlightHint = {
  kind: string
  pos: number
  itemLabel: string
  itemPath: string
}
type HighlightMap = HighlightHint[]

const composeMapForHighlight = (result: SuggestResult) => {
  return order
    .map((kind) => ({ kind, result: result[kind] }))
    .reduce((acc, d) => {
      const list = d.result.map((item: SuggestData, pos: number) => ({
        kind: d.kind,
        pos,
        itemLabel: item.name,
        itemPath: createPathBaseOnType(item),
      }))
      return [...acc, ...list]
    }, [] as HighlightMap)
}

const SuggestResultList: React.VFC<SuggestResultListProps> = (props) => {
  const { result, keyword, loadingStatus, highlight, handleSubmit } = props
  const router = useRouter()
  const handleSelect = (to: TransitTo) => {
    router.push(to.href, to.as)
  }

  const himap = composeMapForHighlight(result)
  const hint =
    highlight !== null
      ? himap[highlight]
      : { kind: '', pos: 0, itemLabel: '', itemPath: '' }

  if (loadingStatus === 'none') return null
  if (loadingStatus === 'loading') {
    return (
      <SuggestListWrapper key="loading">
        <div
          css={css`
            display: flex;
            height: 32px;
            justify-content: center;
            align-items: center;
          `}
        >
          <svg
            version="1.1"
            id="L4"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 52 52"
            enable-background="new 0 0 0 0"
            xmlSpace="preserve"
            css={css`
              display: block;
              width: 25px;
            `}
          >
            <circle fill={colors.gray60} stroke="none" cx="6" cy="26" r="6">
              <animate
                attributeName="opacity"
                dur="1.6s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1"
              />
            </circle>
            <circle fill={colors.gray60} stroke="none" cx="26" cy="26" r="6">
              <animate
                attributeName="opacity"
                dur="1.6s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.4"
              />
            </circle>
            <circle fill={colors.gray60} stroke="none" cx="46" cy="26" r="6">
              <animate
                attributeName="opacity"
                dur="1.6s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.7"
              />
            </circle>
          </svg>
        </div>
      </SuggestListWrapper>
    )
  }

  if (loadingStatus === 'done' && result.displayLength === 0) {
    return (
      <SuggestListWrapper key="notfound">
        <div
          onMouseDown={handleSubmit}
          css={css`
            color: ${colors.text};
            padding: 7px 0 7px 44px;
            font-size: 14px;
            cursor: pointer;
            background-color: ${colors.white00};
            &:hover {
              background-color: ${colors.gray40};
            }
          `}
        >
          すべての検索結果を見る
        </div>
      </SuggestListWrapper>
    )
  }

  // kind, position
  return (
    <div>
      {result.tags.length > 0 && (
        <SuggestListWrapper key="tags">
          <SuggestList
            keyword={keyword}
            result={result.tags}
            onSelect={handleSelect}
            highlight={hint.kind === 'tags' ? hint.pos : null}
          />
        </SuggestListWrapper>
      )}
      {(result.domesticCompanies.length > 0 ||
        result.overseasCompanies.length > 0) && (
        <SuggestListWrapper key="companies">
          <SuggestList
            keyword={keyword}
            result={result.domesticCompanies}
            onSelect={handleSelect}
            highlight={hint.kind === 'domesticCompanies' ? hint.pos : null}
          />
          <SuggestList
            keyword={keyword}
            result={result.overseasCompanies}
            onSelect={handleSelect}
            highlight={hint.kind === 'overseasCompanies' ? hint.pos : null}
          />
        </SuggestListWrapper>
      )}
      {(result.domesticInvestors.length > 0 ||
        result.overseasInvestors.length > 0) && (
        <SuggestListWrapper key="investors">
          <SuggestList
            keyword={keyword}
            result={result.domesticInvestors}
            onSelect={handleSelect}
            highlight={hint.kind === 'domesticInvestors' ? hint.pos : null}
          />
          <SuggestList
            keyword={keyword}
            result={result.overseasInvestors}
            onSelect={handleSelect}
            highlight={hint.kind === 'overseasInvestors' ? hint.pos : null}
          />
        </SuggestListWrapper>
      )}
    </div>
  )
}

const transitionCss = (
  count: number,
  show: boolean
): SerializedStyles | undefined => {
  let height = count * ITEM_HEIGHT + (5 + 9 + 8)
  if (count === 0) {
    height += ITEM_HEIGHT
  }
  if (show) {
    return css`
      max-height: ${height}px;
      opacity: 1;
      transition:
        max-height 0.18s ${EASING_FAST},
        opacity 0.16s ${EASING_SLOW};
    `
  } else {
    return css`
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transition:
        max-height 0.16s ${EASING_FAST},
        opacity 0.14s ${EASING_SLOW};
    `
  }
}

type SuggestListWrapperProps = {
  children: React.ReactNode
}

const SuggestListWrapper: React.FC<SuggestListWrapperProps> = (props) => {
  return (
    <div
      css={css`
        position: relative;
        padding: 4px 0;
        ::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          width: calc(100% - 16px * 2);
          height: 0;
          margin-left: 16px;
          border-top: 1px solid ${colors.border};
        }
      `}
      {...props}
    />
  )
}
