import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { colors } from 'plume-ui'
import { TransitionStatus } from 'react-transition-group/Transition'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import { EASING_FAST } from '@/lib/easings'

export const CloseButton: React.FC<{
  status: TransitionStatus
  onClick: React.MouseEventHandler
}> = ({ status, onClick }) => {
  return (
    <div
      onClick={onClick}
      css={css`
        cursor: pointer;
        ${transitionCss(status)}
      `}
    >
      <IcomoonIcon
        name="close2"
        css={css`
          font-size: 16px;
          color: ${colors.black10};
          display: inline-block;
          margin: 4px 12px 0 0;
        `}
      />
    </div>
  )
}

const transitionCss = (
  status: TransitionStatus
): SerializedStyles | undefined => {
  switch (status) {
    case 'entering':
    case 'entered':
      return css`
        height: auto;
        opacity: 0.2;
        &:hover {
          opacity: 0.3;
        }
        transition:
          height 0s,
          opacity 0.14s ${EASING_FAST};
      `
    case 'exiting':
      return css`
        opacity: 0;
        transition: opacity 0.12s ${EASING_FAST};
      `
    case 'exited':
      return css`
        height: 0;
        opacity: 0;
      `
    default:
      return undefined
  }
}
