// NOTE: Doesn't support half-katakana
export const toHiragana = (text: string): string => {
  return text.replace(/[\u30a1-\u30f6]/g, (match) => {
    var chr = match.charCodeAt(0) - 0x60
    return String.fromCharCode(chr)
  })
}

export const toKatakana = (text: string): string => {
  return text.replace(/[\u3041-\u3096]/g, (match) => {
    var chr = match.charCodeAt(0) + 0x60
    return String.fromCharCode(chr)
  })
}

// NOTE: Doesn't support multiple keywords delimited by spaces
export const emphasize = (text: string, keyword: string): string => {
  keyword = escape(keyword)
  const raw = `${keyword}|${toHiragana(keyword)}|${toKatakana(keyword)}`
  const pattern = new RegExp(raw, 'gi')
  return text.replace(pattern, `<strong>$&</strong>`)
}

// Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
const escape = (pattern: string) =>
  pattern.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
