import { css } from '@emotion/react'
import { colors } from 'plume-ui'

type ItemProps = {
  itemName: string
  path: string
}

export const ProfileMenuItem: React.VFC<ItemProps> = (props) => {
  return (
    <a href={props.path}>
      <div
        css={css`
          text-decoration: none;
          transition: none;
          font-size: 14px;
          letter-spacing: 0.02em;
          color: ${colors.text};
          padding: 12px 16px;
          height: 38px;
          &:hover {
            background-color: ${colors.backgroundGray};
          }
        `}
      >
        {props.itemName}
      </div>
    </a>
  )
}
