import { CompanyLogo } from 'plume-ui'
import React from 'react'
import { NotificationItem } from '../../types'
import {
  organizationDefaultImagePath,
  organizationTypeFromPath,
} from '@/lib/organization'
import { SerializedStyles, css } from '@emotion/react'

type Props = {
  item: NotificationItem
  _css?: SerializedStyles
}

export const OrganizationLogo: React.FC<Props> = (props) => {
  let url = props.item.imageUrl
  if (!url) {
    url = organizationDefaultImagePath(
      organizationTypeFromPath(props.item.path)
    )
  }
  return (
    <CompanyLogo
      css={css`
        ${props._css}
      `}
      name={props.item.title}
      src={url}
    />
  )
}
