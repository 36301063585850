import { css } from '@emotion/react'
import { colors } from 'plume-ui'
import { loginPath } from '@/lib/url'
import { IcomoonIcon } from '../atoms/IcomoonIcon'
import { APPBAR_HEIGHT } from './AppBarForMobile'
import Link from 'next/link'
import { Logo } from '../common/Logo'
import { MobileSideMenu } from '../view/MobileSideMenu'
import React from 'react'

type Props = {
  setSearchModalOpened: (isOpen: boolean) => void
}

export const GuestAppBarForMobile: React.FC<Props> = (props) => {
  const [isMenuOpened, setIsMenuOpened] = React.useState(false)
  return (
    <div
      css={css`
        color: ${colors.white00};
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: ${APPBAR_HEIGHT};
        width: 100%;
        color: ${colors.black10};
      `}
      id="mobile-global-navigation"
    >
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <MobileSideMenu
          isOpen={isMenuOpened}
          toggleIsOpen={() => setIsMenuOpened((prev) => !prev)}
          pageType="product"
          data-testid="global-menu-icon"
        />
        <p
          css={css`
            margin: 0;
            margin-inline-start: 12px;
          `}
        >
          <Link href="/" passHref>
            <a
              css={css`
                display: block;
              `}
              className="speeda-logo"
            >
              <Logo type="double" />
            </a>
          </Link>
        </p>
      </div>
      <div
        css={css`
          display: flex;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            column-gap: 8px;
          `}
        >
          <p
            css={css`
              margin: 0;
            `}
          >
            <Link href={loginPath} passHref>
              <a
                css={css`
                  display: inline-block;
                  border: 1px solid ${colors.border};
                  border-radius: 2px;
                  padding-block: 12px;
                  padding-inline: 12px;
                  color: ${colors.text};
                  font-size: 14px;
                  line-height: 1;
                  letter-spacing: 0.02em;
                `}
                data-testid="login-button"
              >
                ログイン
              </a>
            </Link>
          </p>
          <button
            css={css`
              background: none;
              border: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 32px;
              height: 32px;
              flex-basis: 32px;
              font-size: 24px;
              color: ${colors.black10};
            `}
            onClick={() => props.setSearchModalOpened(true)}
            data-testid="search-icon"
          >
            <IcomoonIcon name="search" label="検索" />
          </button>
        </div>
        <button
          css={css`
            background: none;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            flex-basis: 48px;
            font-size: 24px;
            color: ${colors.black10};
          `}
          onClick={() => setIsMenuOpened(true)}
          data-testid="global-menu-icon"
        >
          <IcomoonIcon name="menu" label="メニューを開く" />
        </button>
      </div>
    </div>
  )
}
