import { css } from '@emotion/react'
import { colors } from 'plume-ui'
import { formatDate } from '@/lib/date'
import { formatMessage } from '@/lib/notifications'
import { NotificationItem } from '../../../types'
import { OrganizationLogo } from '../../common/OrganizationLogo'

type NotificationMenuItemProps = {
  item: NotificationItem
}

export const NotificationMenuItem: React.VFC<NotificationMenuItemProps> = (
  props
) => {
  return (
    <a href={props.item.path}>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          padding: 12px 16px;
          align-items: flex-start;
          border-bottom: 1px solid ${colors.gray50};
          width: 368px;
          height: 80px;
          gap: 8px;
        `}
      >
        <div>
          <OrganizationLogo
            item={props.item}
            _css={css`
              width: 56px !important;
              height: 56px !important;
              padding: 0;
            `}
          />
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            width: 272px;
            height: 54px;
          `}
        >
          <div
            className="notification-body"
            css={css`
              width: 272px;
              height: 36px;
              font-size: 12px;
              line-height: 150%;
              letter-spacing: 0.03em;
              color: ${colors.text};
            `}
          >
            {formatMessage(props.item.body)}
          </div>
          <div
            css={css`
              width: 272px;
              height: 18px;
              color: ${colors.textLight};
              font-size: 12px;
              line-height: 150%;
              display: flex;
              align-items: center;
              letter-spacing: 0.03em;
            `}
          >
            {formatDate(props.item.createdAt, 'yyyy/MM/dd')}
          </div>
        </div>
      </div>
    </a>
  )
}
