import { css } from '@emotion/react'
import { colors, Divider } from 'plume-ui'
import { useUser } from '../../../contexts/AuthContext'
import {
  myPagePath,
  followListPath,
  downloadHistoryPath,
  statusSettingPath,
  logoutPath,
} from '@/lib/url'
import { isLatestEnterpriseOrTrial } from '@/lib/user'
import { Menu } from './Menu'
import { ProfileMenuItem } from './ProfileMenuItem'
import { npidLogout } from '@/lib/api/auth'

type MenuProps = {
  isOpen: boolean
}

export const ProfileMenu: React.FC<MenuProps> = (props) => {
  const user = useUser()
  const handleLogout = () => {
    npidLogout()
    location.href = logoutPath
  }

  return (
    <Menu width={192} show={props.isOpen} id="account-menu">
      <ProfileMenuItem itemName="アカウント設定" path={myPagePath} />
      <ProfileMenuItem itemName="フォローリスト" path={followListPath} />
      <ProfileMenuItem itemName="ダウンロード" path={downloadHistoryPath} />
      {isLatestEnterpriseOrTrial(user) && (
        <ProfileMenuItem itemName="ステータス設定" path={statusSettingPath} />
      )}
      <Divider
        css={css`
          margin: 4px 0;
        `}
      />
      <a
        css={css`
          text-decoration: none;
          transition: none;
          font-size: 14px;
          letter-spacing: 0.02em;
          display: flex;
          flex-direction: row;
          align-items: center;
          color: ${colors.text};
          padding: 12px 16px;
          height: 38px;
          &:hover {
            background-color: ${colors.backgroundGray};
          }
        `}
        href="https://help.initial.inc/"
        target="_blank"
        rel="noopener noreferrer"
      >
        ヘルプ
      </a>
      <Divider
        css={css`
          margin: 4px 0;
        `}
      />
      <button
        css={css`
          width: 100%;
          text-align: left;
          background-color: transparent;
          border: none;
          cursor: pointer;
          outline: none;
          padding: 0;
          appearance: none;
          font-size: 14px;
          letter-spacing: 0.02em;
          padding: 12px 16px;
          height: 38px;
          color: ${colors.text};
          &:hover {
            background-color: ${colors.backgroundGray};
          }
          &:focus-visible {
            outline: -webkit-focus-ring-color auto 1px;
          }
        `}
        onClick={handleLogout}
      >
        ログアウト
      </button>
    </Menu>
  )
}
