import { css } from '@emotion/react'
import { isEnterprise } from '@/lib/user'
import { IcomoonIcon } from '../atoms/IcomoonIcon'
import Link from 'next/link'
import { colors } from 'plume-ui'
import { useEffect, useRef, useState } from 'react'
import { useUser } from '../../contexts/AuthContext'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { getNotifications, markAsRead } from '@/lib/api/notification'
import { printError } from '@/lib/log'
import { Notifications } from '../../types'
import { Badge } from '../atoms/Badge'
import { Overlay } from '../atoms/Overlay'
import { ToolbarButton } from '../atoms/ToolbarButton'
import { Feature, ToolbarMenu } from '../atoms/ToolbarMenu'
import { APPBAR_HEIGHT } from './AppBarForMobile'
import { GlobalSearchFormForMobile } from './molucule/GlobalSearchFormForMobile'
import { Logo } from '../common/Logo'
import { ProfileImage } from './molucule/ProfileImage'
import { MobileSideMenu } from './molucule/MobileSideMenu'

type Props = {
  setSearchModalOpened: (isOpen: boolean) => void
}

export const EnterpriseAppBarForMoblie: React.FC<Props> = (props) => {
  const user = useUser()

  const refButtonGroup = useRef<HTMLDivElement>(null)
  const refMenuGroup = useRef<HTMLDivElement>(null)
  useOnClickOutside([refButtonGroup, refMenuGroup], () =>
    setToolbarOpened(undefined)
  )
  const [sideMenuOpened, setSideMenuOpened] = useState(false)
  const [toolbarOpened, setToolbarOpened] = useState<Feature | undefined>()
  const [notifications, setNotifications] = useState<Notifications | null>(null)
  useEffect(() => {
    getNotifications()
      .then((res) => setNotifications(res))
      .catch((err) => console.log(err)) // TODO: Show error
  }, [])

  const handleMenu = (name: Feature) => {
    setToolbarOpened(toolbarOpened === name ? undefined : name)
  }

  const toggleIsOpen = () => setSideMenuOpened((prev) => !prev)

  return (
    <>
      <div
        css={css`
          color: ${colors.black10};
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 auto;
          height: ${APPBAR_HEIGHT};
        `}
        id="mobile-enterprise-global-navigation"
      >
        <p
          css={css`
            margin: 0;
            margin-inline-start: 12px;
          `}
        >
          <Link href={'/'} passHref>
            <a
              css={css`
                &:hover {
                  opacity: 0.75;
                }
              `}
              className="speeda-logo"
            >
              <Logo type="double" />
            </a>
          </Link>
        </p>
        <GlobalSearchFormForMobile />
        <nav
          ref={refButtonGroup}
          css={css`
            color: ${colors.black10};
            font-size: 24px;
            display: flex;
            align-items: center;
          `}
        >
          <ul
            css={css`
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              column-gap: 8px;
            `}
          >
            <li>
              <ToolbarButton onClick={() => props.setSearchModalOpened(true)}>
                <button
                  css={css`
                    padding: 0;
                    background: none;
                    border: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    width: 32px;
                    height: 32px;
                    color: ${colors.black10};
                  `}
                >
                  <IcomoonIcon name="search" label="検索" />
                </button>
              </ToolbarButton>
            </li>
            {isEnterprise(user) && (
              <li>
                <a
                  data-testid="community-link"
                  href="/sso/bridge/commmune"
                  target="_blank"
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    width: 32px;
                    height: 32px;
                  `}
                >
                  <IcomoonIcon
                    css={css`
                      color: ${colors.black10};
                    `}
                    name="initial-circle"
                    label="オンラインコミュニティ"
                  />
                </a>
              </li>
            )}
            <li>
              <ToolbarButton
                onClick={() => {
                  markAsRead().catch(printError)
                  handleMenu('notification')
                }}
              >
                <button
                  css={css`
                    padding: 0;
                    background: none;
                    border: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    width: 32px;
                    height: 32px;
                    color: ${colors.black10};
                  `}
                >
                  <Overlay
                    show={Boolean(
                      notifications?.count && notifications.count !== '0'
                    )}
                    overlay={
                      <Badge>{notifications ? notifications.count : ''}</Badge>
                    }
                    top={-4}
                    right={-4}
                  >
                    <IcomoonIcon name="bell" />
                  </Overlay>
                </button>
              </ToolbarButton>
            </li>
            <li>
              <ToolbarButton
                onClick={() => handleMenu('profile')}
                className="header-user-icon"
              >
                <button
                  css={css`
                    padding: 0;
                    background: none;
                    border: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    width: 32px;
                    height: 32px;
                    color: ${colors.black10};
                  `}
                >
                  <ProfileImage user={user} />
                </button>
              </ToolbarButton>
            </li>
          </ul>
          <button
            id="sidemenu-toggle"
            onClick={toggleIsOpen}
            css={css`
              padding: 0;
              background: none;
              border: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 48px;
              height: 48px;
              flex-basis: 48px;
              font-size: 24px;
              color: ${colors.black10};
              &:hover {
                opacity: 0.75;
              }
            `}
          >
            <IcomoonIcon
              name={sideMenuOpened ? 'close1' : 'menu'}
              label="メニューを開く"
              data-appcues="sidemenu-toggle"
            ></IcomoonIcon>
          </button>
        </nav>
        <MobileSideMenu
          isOpen={sideMenuOpened}
          toggleIsOpen={toggleIsOpen}
          user={user}
        ></MobileSideMenu>
      </div>
      <div ref={refMenuGroup}>
        <ToolbarMenu
          opened={toolbarOpened}
          notifications={notifications ? notifications.data : []}
        />
      </div>
    </>
  )
}
