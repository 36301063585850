import { colors, mq } from 'plume-ui'
import { css } from '@emotion/react'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import React from 'react'

type Props = {
  isOpen: boolean
  closeModal: () => void
}

export const SearchModal: React.FC<Props> = (props) => {
  return (
    <div
      css={css`
        position: fixed;
        z-index: 100;
        background-color: ${colors.black30};
        right: 0;
        top: 0;
        transition: 0.3s;
        width: 100%;
        height: 100%;
        display: ${props.isOpen ? 'block' : 'none'};
        ${mq.large} {
          display: none;
        }
      `}
    >
      <span
        onClick={props.closeModal}
        css={css`
          display: flex;
          position: fixed;
          top: 0;
          right: 0;
          height: 55px;
          width: 55px;
          font-weight: bold;
          color: ${colors.white00};
        `}
      >
        <IcomoonIcon
          name={'close'}
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 55px;
            height: 55px;
            background-color: ${colors.black20};
            font-size: 24px;
          `}
        ></IcomoonIcon>
      </span>
      <div
        css={css`
          margin-top: 80px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 20px;
        `}
      >
        <form
          action="/search"
          method="get"
          css={css`
            width: 100%;
            padding: 0;
            height: 40px;
            display: block;
          `}
        >
          <div
            css={css`
              background-color: ${colors.gray40};
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <input
              name="search[keywords]"
              placeholder="スタートアップ、投資家、記事などを検索"
              type="text"
              css={css`
                font-size: 14px;
                line-height: 1.3;
                padding: 10px 0 10px 10px;
                width: 100%;
                border: none;
                background-color: transparent;
                &::placeholder {
                  color: ${colors.textLight};
                  font-size: 14px;
                }
                &:focus {
                  outline: none;
                }
              `}
            />
            <button
              type="submit"
              css={css`
                height: 38px;
                border: none;
                background-color: ${colors.gray80};
                padding: 2px 8px;
              `}
            >
              <IcomoonIcon
                name="search"
                css={css`
                  font-size: 20px;
                  color: ${colors.gray40};
                `}
              ></IcomoonIcon>
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
