import { css } from '@emotion/react'
import { User } from '../../../types'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import { colors } from 'plume-ui'

type Props = {
  user?: User | undefined | null
}

export const ProfileImage: React.FC<Props> = (props) => {
  return props.user?.imageUrl ? (
    <img
      css={css`
        width: 21px;
        height: 21px;
        object-fit: cover;
        border-radius: 50%;
        max-width: unset;
      `}
      src={props.user.imageUrl}
    />
  ) : (
    <IcomoonIcon
      name="person"
      css={css`
        color: ${colors.black10};
        font-size: 24px;
      `}
    />
  )
}
