import { css } from '@emotion/react'
import React from 'react'
import { colors } from 'plume-ui'

type Props = {
  className?: string
  children: React.ReactNode
}

export const Badge: React.FC<Props> = (props) => {
  return (
    <span
      css={css`
        border-radius: 8px;
        min-width: 16px;
        height: 16px;
        background-color: ${colors.red60};
        color: ${colors.white00};
        font-size: 11px;
        line-height: 1;
        padding: 0 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
      className={props.className}
    >
      {props.children}
    </span>
  )
}
