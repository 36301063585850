import { Tooltip, colors } from 'plume-ui'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { GlobalSearchForm } from './molucule/GlobalSearchForm'
import { Notifications, User } from '../../types'
import {
  isEnterprise,
  isLoggedIn,
  isModerator,
  isUserManager,
} from '@/lib/user'
import {
  loginPath,
  isCurrentPathIncluded,
  downloadDocumentUrl,
  enterpriseUrl,
  customerInterviewsUrl,
  seminarsUrl,
  resourcesUrl,
} from '@/lib/url'
import { GlobalNavigationTab } from './molucule/GlobalNavigationTab'
import { TabMenuList } from './molucule/TabMenuList'
import { TabMenuListItem } from './molucule/TabMenuListItem'
import { getNotifications, markAsRead } from '@/lib/api/notification'
import { printError } from '@/lib/log'
import Link from 'next/link'
import { ProfileButton } from './molucule/ProfileButton'
import { NotificationButton } from './molucule/NotificationButton'
import { ModeratorButton } from './molucule/ModeratorButton'
import { useRouter } from 'next/router'
import { Logo } from '../common/Logo'
import { ToolbarButton } from '../atoms/ToolbarButton'
import { IcomoonIcon } from '../atoms/IcomoonIcon'
import { useUser } from '../../contexts/AuthContext'

export const APPBAR_HEIGHT = '64px'
export const GLOBAL_MENU_BUTTON_WIDTH = '55px'
export const ENTERPRISE_DETAIL_BUTTON_WIDTH = '168px'
export const LOGIN_BUTTON_WIDTH = '172px'
export const USER_MENU_WIDTH = '72px'
export const NOTIF_ICON_WIDTH = '40px'
export const USER_ICON_WIDTH = '32px'
export const MODERATOR_ICON_WIDTH = '40px'

type Props = {
  user: User
}

export const AppBar: React.FC<Props> = (props) => {
  // Notification Data
  const [notifications, setNotifications] = useState<Notifications | undefined>(
    undefined
  )
  useEffect(() => {
    getNotifications()
      .then((res) => setNotifications(res))
      .catch((err) => printError(err))
  }, [])
  const user = useUser()
  const router = useRouter()

  const requestMarkAsRead = () => {
    markAsRead().catch((err) => console.log(err))
  }

  return (
    <>
      <div
        css={css`
          height: ${APPBAR_HEIGHT};
        `}
      />
      <div
        id="global-navigation"
        css={css`
          height: ${APPBAR_HEIGHT};
          width: 100%;
          background-color: ${colors.white00};
          border-bottom: 1px solid ${colors.borderLight};
          padding: 0 24px;
          display: flex;
          justify-content: space-between;
          position: fixed;
          top: 0px;
          z-index: 100;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 16px;
            width: auto;
            flex-grow: 1;
          `}
        >
          <Link href={'/'} passHref>
            <a
              css={css`
                &:hover {
                  opacity: 0.75;
                }
              `}
              className="speeda-logo"
            >
              <Logo type="double" />
            </a>
          </Link>
          <GlobalSearchForm />
        </div>
        {isLoggedIn(props.user) ? (
          <nav
            css={css`
              display: flex;
              align-items: center;
              justify-content: space-between;
              column-gap: 24px;
            `}
            data-testid="enterprise-global-navigation"
          >
            <div
              css={css`
                display: flex;
                gap: 24px;
                flex-direction: row;
                justify-content: space-between;
              `}
            >
              <GlobalNavigationTab
                url="/companies"
                tabName="スタートアップ"
                isActive={isCurrentPathIncluded(router.pathname, [
                  '/companies.*',
                ])}
                onClickNavigationTab={() =>
                  // NOTE: ユーザー要望の仕様を維持するために必要な処理なので注意。
                  // スタートアップ一覧から個社ページに遷移したあと、ブラウザバックでスタートアップ一覧に戻った時に
                  // 検索条件を保持したい、という要望を実現するためにSession Storageに保存する方法を採用した。
                  // ただ、保存した条件をいつクリアするのか？というところで、苦し紛れにメニュークリックで削除している。
                  sessionStorage.removeItem('search_condition')
                }
              />
              <GlobalNavigationTab
                tabName="資金調達"
                slugName="finance"
                isActive={isCurrentPathIncluded(router.pathname, [
                  '/rounds.*',
                  '/finance_news.*',
                ])}
              >
                <TabMenuList>
                  <TabMenuListItem itemName="資金調達ラウンド" url="/rounds" />
                  <TabMenuListItem
                    itemName="資金調達ニュース"
                    url="/finance_news"
                  />
                </TabMenuList>
              </GlobalNavigationTab>
              <GlobalNavigationTab
                tabName="投資家"
                slugName="investors"
                isActive={isCurrentPathIncluded(router.pathname, [
                  '/investors.*',
                  '/funds.*',
                  '/venture_supports.*',
                ])}
              >
                <TabMenuList>
                  <TabMenuListItem itemName="投資家" url="/investors" />
                  <TabMenuListItem itemName="ファンド" url="/funds" />
                  <TabMenuListItem
                    itemName="ベンチャー支援"
                    url="/venture_supports"
                  />
                </TabMenuList>
              </GlobalNavigationTab>
              <GlobalNavigationTab
                url="/tags"
                tabName="タグ"
                isActive={isCurrentPathIncluded(router.pathname, ['/tag.*'])}
              />
              <GlobalNavigationTab
                tabName="レポート"
                slugName="reports"
                isActive={isCurrentPathIncluded(router.pathname, [
                  `/articles.*`,
                  `/library.*`,
                ])}
              >
                <TabMenuList>
                  <TabMenuListItem itemName="オリジナル記事" url="/articles" />
                  <TabMenuListItem
                    itemName="調査・分析レポート"
                    url="/library"
                  />
                  {isEnterprise(user) && (
                    <>
                      <TabMenuListItem
                        itemName="Startup Weekly Report"
                        external={true}
                        url={`${process.env['COMMMUNE_ENDPOINT']}/view/box?boxId=RYLkjCMQl&categoryIndex=4`}
                      />
                      <TabMenuListItem
                        itemName="Global Startup Pickups"
                        external={true}
                        url={`${process.env['COMMMUNE_ENDPOINT']}/view/box?boxId=RYLkjCMQl&categoryIndex=5`}
                      />
                    </>
                  )}
                </TabMenuList>
              </GlobalNavigationTab>
              <GlobalNavigationTab
                tabName="もっとみる"
                slugName="others"
                isActive={isCurrentPathIncluded(router.pathname, [
                  '/people.*',
                  '/feeds.*',
                  '/pages/venture_maps.*',
                  '/custom_report/main.*',
                  '/pages/dashboard_pickup.*',
                ])}
              >
                <TabMenuList>
                  <TabMenuListItem itemName="人物" url="/people" />
                  <TabMenuListItem itemName="ニュースエンジン" url="/feeds" />
                  <TabMenuListItem
                    itemName="ベンチャーマップ"
                    url="/pages/venture_maps"
                  />
                  <TabMenuListItem
                    itemName="企業リスト"
                    url="/custom_report/main"
                  />
                  <TabMenuListItem
                    itemName="ダッシュボード"
                    url="/pages/dashboard_pickup"
                  />
                </TabMenuList>
              </GlobalNavigationTab>
            </div>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 8px;
              `}
            >
              {isEnterprise(props.user) && (
                <div className="initial-circle">
                  <a
                    id="initial-circle-link"
                    href="/sso/bridge/commmune"
                    target="_blank"
                    css={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 50%;
                      width: 32px;
                      height: 32px;
                      &:hover {
                        background: rgba(0, 0, 0, 0.03);
                      }
                    `}
                  >
                    <Tooltip label="オンラインコミュニティ" place="bottom">
                      <IcomoonIcon
                        css={css`
                          color: ${colors.black10};
                          font-size: 24px;
                        `}
                        name="initial-circle"
                        label="オンラインコミュニティ"
                      />
                    </Tooltip>
                  </a>
                </div>
              )}
              <NotificationButton
                notifications={notifications}
                requestMarkAsRead={requestMarkAsRead}
              />
              {isModerator(props.user) ? (
                <ModeratorButton />
              ) : isUserManager(props.user) ? (
                <ToolbarButton show={isUserManager(props.user)}>
                  <a
                    href="/admin/users"
                    target="_blank"
                    css={css`
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      cursor: pointer;
                      width: 32px;
                      height: 32px;
                      &:hover {
                        border-radius: 50%;
                        background: rgba(0, 0, 0, 0.03);
                      }
                    `}
                  >
                    <IcomoonIcon
                      css={css`
                        &:before {
                          color: ${colors.black10};
                          font-size: 24px;
                        }
                      `}
                      name="people2"
                      className="user-management-menu"
                    />
                  </a>
                </ToolbarButton>
              ) : null}
              <ProfileButton user={props.user} />
            </div>
          </nav>
        ) : (
          <>
            <nav
              css={css`
                display: flex;
                gap: 24px;
                height: 100%;
                align-items: center;
                margin-right: 24px;
                > a {
                  color: inherit;
                  height: 48px;
                  padding: 15px 0;
                  font-size: 14px;
                  line-height: 125%;
                  letter-spacing: 0.02em;
                  transition: none;
                  :hover {
                    border-bottom: 2px solid ${colors.gray50};
                  }
                }
              `}
              data-testid="guest-global-navigation"
            >
              <Link href={enterpriseUrl} passHref>
                <a
                  target="_blank"
                  rel="noopener noreferer"
                  data-testid="global-nav-link"
                >
                  スピーダとは
                </a>
              </Link>
              <Link href={customerInterviewsUrl} passHref>
                <a
                  target="_blank"
                  rel="noopener noreferer"
                  data-testid="global-nav-link"
                >
                  導入事例
                </a>
              </Link>
              <Link href={seminarsUrl} passHref>
                <a
                  target="_blank"
                  rel="noopener noreferer"
                  data-testid="global-nav-link"
                >
                  セミナー
                </a>
              </Link>
              <Link href={resourcesUrl} passHref>
                <a
                  target="_blank"
                  rel="noopener noreferer"
                  data-testid="global-nav-link"
                >
                  資料・レポート
                </a>
              </Link>
            </nav>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
              `}
            >
              <a
                id="document-download-link"
                href={downloadDocumentUrl}
                target="_blank"
                css={css`
                  height: 40px;
                  display: flex;
                  align-items: center;
                  padding: 14px 17px;
                  white-space: nowrap;
                  font-size: 12px;
                  font-weight: 700;
                  border-radius: 4px;
                  border: 1px solid ${colors.black10};
                  color: ${colors.white00};
                  background-color: ${colors.black10};
                  :hover {
                    background-color: ${colors.black20};
                  }
                `}
              >
                資料ダウンロード
              </a>
              <a
                id="login"
                href={loginPath}
                css={css`
                  width: 80px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  padding: 14px 15.5px;
                  white-space: nowrap;
                  font-size: 12px;
                  font-weight: 700;
                  border-radius: 2px;
                  border: 1px solid ${colors.black10};
                  background-color: ${colors.white00};
                  color: ${colors.black10};
                  :hover {
                    color: ${colors.white00};
                    background-color: ${colors.black10};
                  }
                `}
              >
                ログイン
              </a>
            </div>
          </>
        )}
      </div>
    </>
  )
}
