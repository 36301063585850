import { css } from '@emotion/react'
import { colors } from 'plume-ui'
import { useState } from 'react'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import Link from 'next/link'

type Props = {
  url?: string
  tabName: string
  slugName?: string
  isActive: boolean
  children?: React.ReactNode
  onClickNavigationTab?: () => void
}

export const GlobalNavigationTab: React.FC<Props> = (props) => {
  const [openTabMenu, setOpenTabMenu] = useState(false)
  const href = !props.children ? props.url!! : '#'

  return (
    <div
      className="global-navigation-tab"
      css={css`
        position: relative;
        display: flex;
      `}
      onMouseOver={() => setOpenTabMenu(true)}
      onMouseLeave={() => setOpenTabMenu(false)}
      onClick={() => {
        if (props.onClickNavigationTab) {
          props.onClickNavigationTab()
        }
      }}
    >
      {props.children ? (
        <button
          aria-expanded={openTabMenu}
          aria-controls={
            props.slugName ? `global-nav-item-${props.slugName}` : undefined
          }
          css={css`
            color: inherit;
            height: 48px;
            padding: 15px 0;
            font-size: 14px;
            line-height: 125%;
            letter-spacing: 0.02em;
            cursor: pointer;
            border: 0;
            border-bottom: 2px solid transparent;
            background-color: transparent;
            ${props.isActive
              ? `border-bottom-color: ${colors.black10};`
              : `&:hover {
              border-bottom-color: ${colors.gray50};
            }`}
            &:focus-visible {
              outline: -webkit-focus-ring-color auto 1px;
            }
          `}
          onClick={() => {
            setOpenTabMenu(!openTabMenu)
          }}
        >
          {props.tabName}
          <IcomoonIcon
            css={css`
              font-size: 7px;
              padding-left: 6px;
              position: relative;
              top: -1.5px;
            `}
            name="arrow1-down"
          />
        </button>
      ) : (
        <Link href={href} passHref>
          <a
            css={css`
              color: inherit;
              height: 48px;
              padding: 15px 0;
              font-size: 14px;
              line-height: 125%;
              letter-spacing: 0.02em;
              transition: none;
              text-decoration: none;
              ${props.isActive
                ? `border-bottom: 2px solid ${colors.black10};`
                : `&:hover {
              border-bottom: 2px solid ${colors.gray50};
            }`}
              &::after {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                content: '';
              }
            `}
          >
            {props.tabName}
          </a>
        </Link>
      )}
      {openTabMenu && (
        <div
          id={props.slugName ? `global-nav-item-${props.slugName}` : undefined}
          css={css`
            position: absolute;
            left: 0;
            top: 48px;
            background-color: ${colors.white00};
            z-index: 10;
            border-radius: 8px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            padding: 8px 0;
          `}
        >
          {props.children}
        </div>
      )}
    </div>
  )
}
