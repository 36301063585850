import { useEffect } from 'react'

const events: Array<keyof DocumentEventMap> = ['mousedown', 'touchstart']

// Based on https://usehooks.com/useOnClickOutside/
export const useOnClickOutside = (
  refs: React.RefObject<
    HTMLButtonElement | HTMLDivElement | HTMLFormElement | HTMLUListElement
  >[],
  onClick: Function
) => {
  useEffect(() => {
    const listener = (event: Event) => {
      for (const ref of refs) {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return
        }
      }

      onClick(event)
    }

    for (const name of events) {
      document.addEventListener(name, listener)
    }

    return () => {
      for (const name of events) {
        document.removeEventListener(name, listener)
      }
    }
  }, [refs, onClick])
}
