import { useState } from 'react'
import { css } from '@emotion/react'
import { Notifications, User } from '../../../types'
import { Badge } from '../../atoms/Badge'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import { Overlay } from '../../atoms/Overlay'
import { NotificationMenu } from './NotificationMenu'
import { colors } from 'plume-ui'

const duration = 2 * 1000 // ms

type Props = {
  user?: User | undefined
  notifications: Notifications | undefined
  requestMarkAsRead: () => void
}

export const NotificationButton: React.FC<Props> = (props) => {
  const [notificationOpened, setNotificationOpened] = useState(false)
  const [lastRequest, setLastRequest] = useState(0)

  const handleRequest = () => {
    // チャタリング防止
    const now = new Date().getTime()
    if (lastRequest + duration < now) {
      props.requestMarkAsRead()
      setLastRequest(now)
    }
  }

  return (
    <div
      className="notification"
      css={css`
        display: flex;
        justify-content: center;
        width: 32px;
        height: 32px;
      `}
      onMouseOver={() => {
        handleRequest()
        setNotificationOpened(true)
      }}
      onMouseLeave={() => setNotificationOpened(false)}
    >
      <button
        aria-expanded={notificationOpened}
        aria-controls="global-nav-notification"
        css={css`
          padding: 0;
          border: none;
          cursor: pointer;
          background-color: transparent;
          display: flex;
          align-items: center;
          &:hover .circle {
            background: rgba(0, 0, 0, 0.03);
          }
          &:focus-visible {
            outline: -webkit-focus-ring-color auto 1px;
          }
        `}
        onClick={() => {
          handleRequest()
          setNotificationOpened((prev) => !prev)
        }}
      >
        <div
          className="circle"
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 50px;
          `}
        >
          <Overlay
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
            `}
            show={Boolean(
              props.notifications?.count && props.notifications.count !== '0'
            )}
            overlay={
              <Badge className="notification-count">
                {props.notifications ? props.notifications.count : ''}
              </Badge>
            }
            top={-4}
            right={-4}
          >
            <IcomoonIcon
              css={css`
                color: ${colors.black10};
                font-size: 24px;
              `}
              name="bell"
            />
          </Overlay>
        </div>
      </button>
      <NotificationMenu
        data={props.notifications ? props.notifications.data : []}
        isOpen={notificationOpened}
      />
    </div>
  )
}
