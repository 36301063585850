import { ApiResponse } from '../../types'
import { CompanyData } from '../../types/api'
import { basePath, callAPI } from './common'

export const escapeKeyword = (keyword: string) =>
  encodeURIComponent(keyword.replace('#', ''))

export const suggest = async (keyword: string, options: RequestInit = {}) => {
  const res = await callAPI<ApiResponse<CompanyData[]>>(
    `${basePath}/suggestions?keywords=${escapeKeyword(keyword)}`,
    options
  )
  return res.data
}
