import { colors } from 'plume-ui'
import { css } from '@emotion/react'
import { isLoggedIn } from '@/lib/user'
import React, { useState } from 'react'
import { SearchModal } from './molucule/SearchModal'
import { EnterpriseAppBarForMoblie } from './EnterpriseAppBarForMobile'
import { useUser } from '../../contexts/AuthContext'
import { GuestAppBarForMobile } from './GuestAppBarForMobile'

export const APPBAR_HEIGHT = '56px'
export const INITIAL_LOGO_WIDTH = '125px'
export const GLOBAL_MENU_BUTTON_WIDTH = '55px'
export const ENTERPRISE_DETAIL_BUTTON_WIDTH = '168px'
export const LOGIN_BUTTON_WIDTH = '172px'
export const USER_MENU_WIDTH = '72px'
export const NOTIF_ICON_WIDTH = '40px'
export const USER_ICON_WIDTH = '32px'
export const MODERATOR_ICON_WIDTH = '40px'

export const AppBarForMobile: React.FC = () => {
  const user = useUser()

  // Sidebar / Search Modal
  const [searchModalOpened, setSearchModalOpened] = useState(false)

  return (
    <>
      <div
        css={css`
          height: ${APPBAR_HEIGHT};
        `}
      />
      <div
        css={css`
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
          border-bottom: 1px solid ${colors.gray50};
          height: ${APPBAR_HEIGHT};
          position: fixed;
          background-color: #fff;
          width: 100%;
          z-index: 23;
          top: 0;
        `}
      >
        {isLoggedIn(user) ? (
          <EnterpriseAppBarForMoblie
            setSearchModalOpened={setSearchModalOpened}
          />
        ) : (
          <GuestAppBarForMobile setSearchModalOpened={setSearchModalOpened} />
        )}
      </div>
      <SearchModal
        isOpen={searchModalOpened}
        closeModal={() => setSearchModalOpened(false)}
      ></SearchModal>
    </>
  )
}
