import { css } from '@emotion/react'
import { colors } from 'plume-ui'
import { APPBAR_HEIGHT } from '../AppBar'

type MenuProps = {
  id?: string
  show?: boolean
  width?: number
  className?: string
  children: React.ReactNode
}

export type DataMenuProps<T> = {
  isOpen: boolean
  data: T[]
}

export const Menu: React.FC<MenuProps> = (props) => {
  const show = typeof props.show === 'boolean' ? props.show : true
  return show ? (
    <div
      id={props.id}
      css={css`
        position: fixed;
        right: 24px;
        top: calc(${APPBAR_HEIGHT} - 20px);
        background-color: ${colors.white00};
        z-index: 30;
        width: ${props.width ? props.width : 368}px;
        max-height: 608px;
        border: solid 1px ${colors.gray50};
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        padding: 8px 0;
      `}
      className={props.className}
    >
      {props.children}
    </div>
  ) : null
}
