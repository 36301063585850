import * as React from 'react'
import { css } from '@emotion/react'
import { colors } from 'plume-ui'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import { COMPANY_HREF, TAG_HREF, newTagPath, INVESTOR_HREF } from '@/lib/url'

const ITEM_HEIGHT = 32

type IconName = 'tag' | 'startup' | 'investor'
type SuggestItemRenderer = (
  content: string,
  props: {
    href: string
    as: string
    icon: IconName
    type?: string
  }
) => React.ReactElement

export type TransitTo = { href: string; as: string }
export type SuggestionSelectedHandler = (to: TransitTo) => void

export type TagSuggestItem = {
  type: 'tag'
  id: number
  name: string
  slug: string
  category: string
}

// 実際のレスポンスには記載したフィールド以外も存在してるので注意
export type DomesticCompanySuggestItem = {
  type: 'domestic-company'
  shortname: string
  name: string
}

export type OverseasCompanySuggestItem = {
  type: 'overseas-company'
  globalId: string
  name: string
}

export type DomesticInvestorSuggestItem = {
  type: 'domestic-investor'
  shortname: string
  name: string
}

export type OverseasInvestorSuggestItem = {
  type: 'overseas-investor'
  globalId: string
  name: string
}

export type SuggestData =
  | TagSuggestItem
  | DomesticCompanySuggestItem
  | OverseasCompanySuggestItem
  | DomesticInvestorSuggestItem
  | OverseasInvestorSuggestItem

export const createPathBaseOnType = (item: SuggestData): string => {
  switch (item.type) {
    case 'tag':
      return `${newTagPath(item)}?from=suggest`
    case 'domestic-company':
      return companyPath(item.shortname)
    case 'domestic-investor':
      return investorPath(item.shortname)
    case 'overseas-company':
      return companyPath(item.globalId)
    case 'overseas-investor':
      return investorPath(item.globalId)
  }
}

export const SuggestItem: React.FC<{
  item: SuggestData
  children: SuggestItemRenderer
}> = ({ item, children }) => {
  const as = createPathBaseOnType(item)
  switch (item.type) {
    case 'tag':
      return children(`#${item.name}`, {
        href: TAG_HREF,
        type: item.type,
        as,
        icon: 'tag',
      })
    case 'domestic-company':
      return children(item.name, {
        href: COMPANY_HREF,
        type: item.type,
        as,
        icon: 'startup',
      })
    case 'domestic-investor':
      return children(item.name, {
        href: INVESTOR_HREF,
        type: item.type,
        as,
        icon: 'investor',
      })
    case 'overseas-company':
      return children(item.name, {
        href: COMPANY_HREF,
        type: item.type,
        as,
        icon: 'startup',
      })
    case 'overseas-investor':
      return children(item.name, {
        href: INVESTOR_HREF,
        type: item.type,
        as,
        icon: 'investor',
      })
  }
}

export const SuggestItemLink: React.FC<{
  as: string
  href: string
  type?: string
  icon: IconName
  highlight: boolean
  children: React.ReactElement
  onClick: SuggestionSelectedHandler
}> = ({ as, href, type, icon, highlight, children, onClick }) => {
  return (
    <li
      css={css`
        display: flex;
        font-size: 14px;
      `}
      className={`suggest-item ${type ? `suggest-item-${type}` : null}`}
    >
      <span
        css={css`
          color: ${colors.text};
          display: flex;
          align-items: center;
          width: 100%;
          height: ${ITEM_HEIGHT}px;
          background-color: ${highlight ? colors.gray40 : colors.white00};
          cursor: pointer;
          &:hover,
          &:focus {
            background-color: ${colors.gray40};
          }
          transition: background-color 0.14s linear;
        `}
        onClick={() => {
          onClick({ href, as })
        }}
      >
        <IcomoonIcon
          name={icon}
          css={css`
            font-size: 18px;
            color: ${colors.gray60};
            display: inline-block;
            padding: 0 8px 0 16px;
          `}
        />
        <div
          css={css`
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
            padding-right: 16px;
            strong {
              color: ${colors.black10};
              font-weight: bold;
            }
          `}
        >
          {children}
        </div>
      </span>
    </li>
  )
}

const companyPath = (shortname: string) =>
  `/companies/${shortname}?from=suggest`

const investorPath = (shortname: string) =>
  `/investors/${shortname}?from=suggest`
