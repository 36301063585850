import { OrganizationType } from '../types'

const tokenToType: { [key: string]: OrganizationType } = {
  companies: 'company',
  investors: 'investor',
  venture_supports: 'venture-support',
  people: 'person',
}

export const organizationTypeFromPath = (
  path: string,
  fallback: OrganizationType = 'company'
) => {
  const token = path.split('/').filter((t) => t)[0]
  let type = tokenToType[token]
  if (!type) {
    type = fallback
  }
  return type
}

const typeToPath = {
  company: '/static/images/default/company.png',
  investor: '/static/images/default/investor.png',
  'venture-support': '/static/images/default/venture_support.png',
  person: '/static/images/default/person.png',
}

export const organizationDefaultImagePath = (
  type: OrganizationType,
  fallback: OrganizationType = 'company'
) => {
  let path = typeToPath[type]
  if (!path) {
    path = typeToPath[fallback]
  }
  return path
}
