import { useState } from 'react'
import { css } from '@emotion/react'
import { User } from '../../../types'
import { ProfileImage } from './ProfileImage'
import { ProfileMenu } from './ProfileMenu'

type Props = {
  user?: User | undefined
}

export const ProfileButton: React.FC<Props> = (props) => {
  const [profileOpened, setProfileOpened] = useState(false)

  return (
    <div
      className="header-user-icon"
      onMouseOver={() => setProfileOpened(true)}
      onMouseLeave={() => setProfileOpened(false)}
      css={css`
        position: relative;
        display: flex;
        max-width: 32px;
      `}
    >
      <button
        aria-expanded={profileOpened}
        aria-controls="account-menu"
        css={css`
          padding: 0;
          cursor: pointer;
          border: none;
          background-color: transparent;
          &:focus-visible {
            outline: -webkit-focus-ring-color auto 1px;
          }
          &:hover .circle {
            background: rgba(0, 0, 0, 0.03);
          }
        `}
        onClick={() => setProfileOpened((prev) => !prev)}
      >
        <div
          className="circle"
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            border-radius: 50px;
          `}
        >
          <ProfileImage user={props.user} />
        </div>
      </button>
      <ProfileMenu isOpen={profileOpened} />
    </div>
  )
}
