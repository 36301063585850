import { css } from '@emotion/react'

type Props = {
  overlay: any
  show?: boolean
  top?: number
  right?: number
  bottom?: number
  left?: number
  children: React.ReactNode
}

export const Overlay: React.FC<Props> = (props) => {
  return (
    <div
      css={css`
        position: relative;
        width: fit-content;
        line-height: 0;
      `}
    >
      {props.children}
      {props.show ? (
        <div
          css={css`
            position: absolute;
            ${typeof props.top === 'number' ? `top: ${props.top}px;` : null}
            ${typeof props.right === 'number'
              ? `right: ${props.right}px;`
              : null}
            ${typeof props.bottom === 'number'
              ? `bottom: ${props.bottom}px;`
              : null}
            ${typeof props.left === 'number' ? `left: ${props.left}px;` : null}
          `}
        >
          {props.overlay}
        </div>
      ) : null}
    </div>
  )
}
