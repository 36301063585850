import { css } from '@emotion/react'
import { Divider, colors } from 'plume-ui'
import { notificationPath } from '@/lib/url'
import { NotificationItem } from '../../../types'
import { DataMenuProps, Menu } from './Menu'
import { NotificationMenuItem } from './NotificationMenuItem'

const MAX_NOTIFICATION_COUNT = 7

export const NotificationMenu: React.FC<DataMenuProps<NotificationItem>> = (
  props
) => {
  return (
    <Menu
      id="global-nav-notification"
      show={props.isOpen}
      className="notification-items"
    >
      {props.data.slice(0, MAX_NOTIFICATION_COUNT).map((item) => (
        <NotificationMenuItem item={item} key={item.id} />
      ))}
      <Divider />
      <a href={notificationPath}>
        <div
          css={css`
            height: 40px;
            padding: 0;
            line-height: 125%;
            display: flex;
            align-items: center;
            justify-content: center;
            letter-spacing: 0.03em;
            color: ${colors.blue50};
            font-size: 14px;
            &:hover {
              background-color: ${colors.backgroundGray};
            }
          `}
        >
          すべて見る
        </div>
      </a>
    </Menu>
  )
}
