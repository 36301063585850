import { Notifications } from '../../types'
import { basePath, callAPI, callAPIRaw } from './common'

export const getNotifications = () => {
  return callAPI<Notifications>(`${basePath}/users/notification`)
}

export const markAsRead = () => {
  return callAPIRaw(`${basePath}/users/mark_as_read`, { method: 'post' })
}
