import { colors } from 'plume-ui'
import { css } from '@emotion/react'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import React from 'react'
import { articlesPath } from '@/lib/url'
import { User } from '../../../types'
import { isEnterprise } from '@/lib/user'
import { useUser } from '../../../contexts/AuthContext'
import Link from 'next/link'
import { Logo } from '../../common/Logo'

type Props = {
  isOpen: boolean
  toggleIsOpen: () => void
  user: User
}

export const MobileSideMenu: React.FC<Props> = (props) => {
  const user = useUser()
  return (
    <nav
      id="sidemenu"
      css={css`
        position: fixed;
        z-index: 23;
        top: 0;
        right: ${props.isOpen ? 0 : '-100%'};
        background-color: ${colors.black10};
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        transition: 0.3s;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <button
          onClick={props.toggleIsOpen}
          css={css`
            background: 0;
            border: 0;
            position: absolute;
            right: 0;
            display: flex;
            padding-block: 16px;
            padding-inline: 16px;
          `}
        >
          <IcomoonIcon
            name={'close'}
            label="メニューを閉じる"
            css={css`
              color: ${colors.textInvert};
              font-size: 24px;
            `}
          ></IcomoonIcon>
        </button>
        <p
          css={css`
            margin: 0;
            flex-basis: 100%;
            padding-block: 15px;
            padding-inline-start: 24px;
          `}
        >
          <Link href={'/'} passHref>
            <a
              css={css`
                display: inline-block;
              `}
              className="speeda-logo"
            >
              <Logo mode="dark" />
            </a>
          </Link>
        </p>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          padding-inline: 24px;
          padding-block-end: 24px;
        `}
      >
        <MobileSideMenuList
          links={[{ href: '/companies', text: 'スタートアップ' }]}
        />
        <MobileSideMenuList
          links={[
            { href: '/rounds', text: '資金調達ラウンド' },
            { href: '/finance_news', text: '資金調達ニュース' },
          ]}
        />
        <MobileSideMenuList
          links={[
            { href: '/investors', text: '投資家' },
            { href: '/funds', text: 'ファンド' },
            { href: '/venture_supports', text: 'ベンチャー支援' },
          ]}
        />
        <MobileSideMenuList links={[{ href: '/tags', text: 'タグ' }]} />
        <MobileSideMenuList
          links={
            isEnterprise(user)
              ? [
                  { href: articlesPath, text: 'オリジナル記事' },
                  { href: '/library', text: '調査・分析レポート' },
                  {
                    href: 'https://initial.commmune.com/view/box?boxId=RYLkjCMQl&categoryIndex=4',
                    text: 'Startup Weekly Report',
                    external: true,
                  },
                  {
                    href: 'https://initial.commmune.com/view/box?boxId=RYLkjCMQl&categoryIndex=5',
                    text: 'Global Startup Pickups',
                    external: true,
                  },
                ]
              : [
                  { href: articlesPath, text: 'オリジナル記事' },
                  { href: '/library', text: '調査・分析レポート' },
                ]
          }
        />
        <MobileSideMenuList
          links={[
            { href: '/people', text: '人物' },
            { href: '/feeds', text: 'ニュースエンジン' },
            { href: '/pages/venture_maps', text: 'ベンチャーマップ' },
            { href: '/custom_report/main', text: '企業リスト' },
            { href: '/pages/dashboard_pickup', text: 'ダッシュボード' },
          ]}
        />
      </div>
    </nav>
  )
}

const MobileSideMenuList: React.VFC<{
  links: { href: string; text: string; external?: boolean }[]
}> = (props) => {
  return (
    <ul
      css={css`
        margin: 0;
        padding: 0;
        background: ${colors.black30};
        border-radius: 8px;
        > li:not(:last-child) {
          border-bottom: 1px solid ${colors.borderStrong};
        }
      `}
    >
      {props.links.map((l, i) => (
        <li
          key={i}
          css={css`
            font-size: 16px;
            > a {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: ${colors.white00};
              padding: 14px 16px;
            }
          `}
        >
          <Link href={l.href} passHref>
            <a
              target={l.external ? '_blank' : undefined}
              rel={l.external ? 'noopener norefferer' : undefined}
            >
              {l.text}
              {l.external && (
                <IcomoonIcon
                  css={css`
                    color: ${colors.gray60};
                    font-size: 12px;
                  `}
                  name="arrow-right-up"
                />
              )}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  )
}
