import React from 'react'
import { css } from '@emotion/react'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import { colors } from 'plume-ui'

export const ModeratorButton: React.VFC = () => {
  return (
    <div className="moderator-icon">
      <a
        css={css`
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 32px;
          height: 32px;
          color: inherit;
          &:hover {
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.03);
          }
        `}
        href="/admin/users"
      >
        <IcomoonIcon
          css={css`
            color: ${colors.black10};
            font-size: 24px;
          `}
          name="gear"
        />
      </a>
    </div>
  )
}
