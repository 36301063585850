import { css } from '@emotion/react'
import { colors } from 'plume-ui'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import Link from 'next/link'

type Props = {
  itemName: string
  url: string
  external?: boolean
}

export const TabMenuListItem: React.VFC<Props> = (props) => {
  return (
    <li>
      <div
        css={css`
          &:hover {
            background-color: ${colors.gray40};
          }
        `}
      >
        <Link href={props.url} passHref>
          <a
            css={css`
              color: inherit;
              text-decoration: none;
              transition: none;
              font-size: 14px;
              line-height: 1;
              letter-spacing: 0.03em;
              display: flex;
              align-items: center;
              justify-content: space-between;
              column-gap: 8px;
              min-height: 40px;
              padding-block: 8px;
              padding-inline: 16px;
            `}
            target={props.external ? '_blank' : undefined}
          >
            <span>{props.itemName}</span>
            {props.external && (
              <IcomoonIcon
                css={css`
                  color: ${colors.gray60};
                  font-size: 16px;
                `}
                name="arrow-right-up"
              />
            )}
          </a>
        </Link>
      </div>
    </li>
  )
}
