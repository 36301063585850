import * as React from 'react'
import { css } from '@emotion/react'
import {
  SuggestItem,
  SuggestItemLink,
  SuggestionSelectedHandler,
  TagSuggestItem,
  DomesticCompanySuggestItem,
  OverseasCompanySuggestItem,
  DomesticInvestorSuggestItem,
  OverseasInvestorSuggestItem,
} from './SuggestItem'
import { emphasize } from '@/lib/highlight'

export type HighlightState = number | null

export const SuggestList: React.FC<{
  keyword: string
  result:
    | TagSuggestItem[]
    | DomesticCompanySuggestItem[]
    | OverseasCompanySuggestItem[]
    | DomesticInvestorSuggestItem[]
    | OverseasInvestorSuggestItem[]
  highlight: HighlightState
  onSelect: SuggestionSelectedHandler
}> = ({ keyword, result, highlight, onSelect }) => (
  <ul
    css={css`
      &&&& {
        margin: 0;
        padding: 0;
      }
    `}
  >
    {result.map((item, i) => (
      <SuggestItem item={item} key={i}>
        {(content, props) => (
          <SuggestItemLink
            onClick={onSelect}
            highlight={i === highlight}
            {...props}
          >
            {markup(content, keyword)}
          </SuggestItemLink>
        )}
      </SuggestItem>
    ))}
  </ul>
)

const markup = (content: string, keyword: string) => {
  const html = emphasize(content, keyword)
  return <span dangerouslySetInnerHTML={{ __html: html }} />
}
