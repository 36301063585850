import { css } from '@emotion/react'
import { mq, colors } from 'plume-ui'
import { Transition } from 'react-transition-group'
import { IcomoonIcon } from '../../atoms/IcomoonIcon'
import { useRouter } from 'next/router'
import { isBrowser } from '@/lib/browser'
import { escapeKeyword } from '@/lib/api/suggestion'
import { useUser } from '../../../contexts/AuthContext'
import { isTrial, isEnterprise } from '@/lib/user'
import { CloseButton } from './CloseButton'
import { EASING_FAST } from '@/lib/easings'
import { isLoggedIn } from '@/lib/user'
import React, { useEffect, useState } from 'react'
import {
  GLOBAL_MENU_BUTTON_WIDTH,
  INITIAL_LOGO_WIDTH,
  LOGIN_BUTTON_WIDTH,
  NOTIF_ICON_WIDTH,
  MODERATOR_ICON_WIDTH,
  USER_ICON_WIDTH,
  ENTERPRISE_DETAIL_BUTTON_WIDTH,
} from '../AppBarForMobile'

const initialKeyword = () => {
  if (!isBrowser || window.location.pathname === 'search') {
    return ''
  }
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get('q') || ''
}

const globalSearchPath = (keyword: string) =>
  `/search?q=${escapeKeyword(keyword)}&from=suggest`

export const GlobalSearchFormForMobile = () => {
  const router = useRouter()
  useEffect(() => {
    router.events.on('routeChangeStart', clearSuggest)
    return () => {
      router.events.off('routeChangeStart', clearSuggest)
    }
  }, [])

  const [keyword, setKeyword] = useState(initialKeyword())
  const [handle, setHandle] = useState<NodeJS.Timeout | null>(null)
  const [req, setReq] = useState(0)
  useEffect(() => {
    if (handle) {
      clearTimeout(handle)
    }
    const newHandle = setTimeout(() => {
      setReq(new Date().getTime())
    }, 350)
    setHandle(newHandle)
  }, [keyword])

  const clearSuggest = () => {
    setKeyword('')
  }

  const [allowed, setAllowed] = useState(false)
  const user = useUser()
  useEffect(() => {
    if (isTrial(user) || isEnterprise(user)) {
      setAllowed(true)
    }
  }, [user])

  const [ctrl, setCtrl] = React.useState<any>(null)
  React.useEffect(() => {
    const run = () => {
      if (ctrl) {
        ctrl.abort()
      }
      const abort = new AbortController()
      setCtrl(abort)
    }
    const cancel = () => {
      if (ctrl) {
        ctrl.abort()
        setCtrl(null)
      }
    }
    if (keyword && allowed) {
      run()
    } else {
      cancel()
    }
  }, [req])
  const refGlobalSearch = React.useRef<HTMLFormElement>(null)

  const [focus, setFocus] = React.useState(false)
  const handleFocus = () => {
    setFocus(true)
  }

  const handleBlur = () => {
    setFocus(false)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.currentTarget.value)
  }

  const refInputField = React.useRef<HTMLInputElement>(null)
  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | null) => {
    e && e.preventDefault()
    router.push(globalSearchPath(keyword))
    if (refInputField.current) {
      refInputField.current.blur()
    }
    clearSuggest()
  }

  const handleClose = () => {
    clearSuggest()
    if (refInputField.current) {
      refInputField.current.focus()
    }
  }

  return (
    <div
      css={css`
        ${mq.untilLarge} {
          width: calc(100% - ${INITIAL_LOGO_WIDTH} - ${LOGIN_BUTTON_WIDTH});
          ${isLoggedIn(user) &&
          css`
            // 20px はヒューリスティックな微調整
            width: calc(
              100% - 20px - ${MODERATOR_ICON_WIDTH} - ${INITIAL_LOGO_WIDTH} -
                ${USER_ICON_WIDTH} - ${NOTIF_ICON_WIDTH} -
                ${GLOBAL_MENU_BUTTON_WIDTH}
            );
          `}
          padding: 0 2%;
        }
        width: calc(
          100% - ${INITIAL_LOGO_WIDTH} - ${LOGIN_BUTTON_WIDTH} -
            ${ENTERPRISE_DETAIL_BUTTON_WIDTH}
        );
        ${isLoggedIn(user) &&
        css`
          width: calc(768px + 8%);
        `};
        padding: 0 8%;
        height: 40px;
        display: none;
        ${mq.large} {
          display: block;
        }
      `}
    >
      <form
        ref={refGlobalSearch}
        action="/search"
        method="get"
        css={css`
          width: 100%;
          height: auto;
          background-color: ${focus ? colors.white00 : colors.backgroundGray};
          border-radius: 2px;
          ${focus ? 'box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);' : ''}
          transition: background-color 0.16s ${EASING_FAST}, box-shadow 0.19s linear 0.07s;
        `}
        onSubmit={handleSubmit}
        className="global-search-form"
      >
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <IcomoonIcon
            name="search"
            css={css`
              font-size: 22px;
              color: ${focus ? colors.cyan60 : colors.textLight};
              display: inline-block;
              margin-left: 10px;
              transition: color 0.16s ${EASING_FAST};
            `}
          />
          <input
            className="search-input"
            name="keyword"
            placeholder="スタートアップ、投資家、オリジナル記事、人物、タグなどを検索"
            type="text"
            autoComplete="off"
            value={keyword}
            ref={refInputField}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            css={css`
              color: ${colors.text};
              width: calc(100% - 50px);
              border: none;
              padding: 10px;
              background-color: transparent;
              outline: none;
              &::placeholder {
                color: ${colors.textLight};
                font-size: 14px;
              }
            `}
          />
          <Transition
            in={keyword.length > 0}
            timeout={{ enter: 140, exit: 120 }}
          >
            {(status) => <CloseButton status={status} onClick={handleClose} />}
          </Transition>
        </div>
      </form>
    </div>
  )
}
