import { css } from '@emotion/react'

type Props = {
  children: React.ReactNode
}

export const TabMenuList: React.FC<Props> = (props) => {
  return (
    <ul
      css={css`
        padding: 0;
        margin: 0;
        min-width: 160px;
        white-space: nowrap;
      `}
    >
      {props.children}
    </ul>
  )
}
