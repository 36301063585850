import { css } from '@emotion/react'

type Props = {
  show?: boolean
  className?: string
  children: React.ReactNode
  onClick?: (e: any) => void
  onMouseOver?: (e: any) => void
  onMouseLeave?: (e: any) => void
}

export const ToolbarButton: React.FC<Props> = (props) => {
  const show = typeof props.show === 'boolean' ? props.show : true
  return show ? (
    <div
      onClick={props.onClick}
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
      css={css`
        cursor: pointer;
        &:hover {
          opacity: 0.75;
        }
      `}
      className={props.className}
    >
      {props.children}
    </div>
  ) : null
}
